import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  IconButton,
  Alert,
  Snackbar,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import axios from "axios";
import { useQuery } from "react-query";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import LineChart, { LineData } from "../../components/LineChart";
import { continuousColorsLegendDefaults } from "@nivo/legends";
import { PATHS } from "../../utils/PathUtils";


interface ClientLineData extends LineData {
  clientId: string;
  
}

const ShowResults = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [accuracyMessage, setAccuracyMessage] = useState("");
  const [epochsMessage, setEpochsMessage] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  
  let params = new URLSearchParams(location.search);
  const clientId = params.get("clientId")
  const jobId = params.get("jobId")
  

  const [isLoading, setIsLoading] = useState(false);
  const [accuracy, setAccuracy] = useState<ClientLineData[]>([]);
  const [epochs, setEpochs] = useState<ClientLineData[]>([]);
  const [tcdice, setTcdice] = useState<ClientLineData[]>([])
  const [wtdice, setWtdice] = useState<ClientLineData[]>([])
  const [etdice, setEtdice] = useState<ClientLineData[]>([])
  const [error, setError] = useState<string | undefined>(undefined);
  
  useEffect(() => {
    setIsLoading(true);
    if(!jobId?.length || !clientId?.length ){
        console.log(`JobId ${jobId} or ClientId ${clientId} is not set`)
        return;
    }
    try {
      axios(
        `${PATHS.FED_ML}/fedml/client/accuracy/${clientId}/${jobId}`
      )
        .then((fetchAccuracy) => {
          if (fetchAccuracy.data?.data?.details) {
            setAccuracyMessage(fetchAccuracy.data?.data.details.message);
          }

          const accuracyData = fetchAccuracy.data?.detailed?.map(
            (accuracy: any, i: number) => {
              const acc = Object.keys(accuracy)[0];
              return { x: accuracy.time, y: accuracy[acc] };
            }
          );

          const jobAccuracy: ClientLineData[] = [
            {
              clientId,
              id:jobId,
              color: colors.greenAccent[500],
              data: accuracyData,
            },
          ];

          setAccuracy(jobAccuracy);
        })
        .catch((accuracyReason) => {
          setAccuracyMessage(
            "Failed to get accuracy: " + JSON.stringify(accuracyReason)
          );
        });

      axios(
        `${PATHS.FED_ML}/fedml/client/epochs/${clientId}/${params.get(
          "jobId"
        )}`
      )
        .then((fetchEpochs) => {
            const epochsData = fetchEpochs.data?.detailed?.map(
                (epoch: any, i: number) => {
                const ep = Object.keys(epoch)[1];
                return { x: epoch.time, y: epoch[ep] };
                }
              );
    
              const jobEpochs: ClientLineData[] = [
                {
                  clientId,
                  id:jobId,
                  color: colors.greenAccent[500],
                  data: epochsData,
                },
              ];

              setEpochs(jobEpochs);

        })
        .catch((epochReason) => {});
      axios(
        `${PATHS.FED_ML}/fedml/client/tcdice/${clientId}/${params.get(
          "jobId"
        )}`
      )
        .then((fetchTcdice) => {
          if (fetchTcdice.data?.detailed.length===0) {
            return
          } 
          const tcdiceData = fetchTcdice.data?.detailed?.map(
                (tcdices: any, i: number) => {
              const acc = Object.keys(tcdices)[0];
              return { x: tcdices.time, y: tcdices[acc] };
                }
              );
    
              const jobTcdice: ClientLineData[] = [
                {
                  clientId,
                  id:jobId,
                  color: colors.greenAccent[500],
                  data: tcdiceData,
                },
              ];

          setTcdice(jobTcdice);
        })
        .catch((tcdiceReason) => {});

      axios(
        `${PATHS.FED_ML}/fedml/client/wtdice/${clientId}/${params.get(
          "jobId"
        )}`
      )
        .then((fetchWtdice) => {
          if (fetchWtdice.data?.detailed.length === 0) {
            return
          } 
          const wtdiceData = fetchWtdice.data?.detailed?.map(
                (wtdices: any, i: number) => {
              const acc = Object.keys(wtdices)[0];
              return { x: wtdices.time, y: wtdices[acc] };
                }
              );
    
              const jobWtdice: ClientLineData[] = [
                {
                  clientId,
                  id:jobId,
                  color: colors.greenAccent[500],
                  data: wtdiceData,
                },
              ];

          setWtdice(jobWtdice);
        })
        .catch((tcdiceReason) => {});
      axios(
        `${PATHS.FED_ML}/fedml/client/etdice/${clientId}/${params.get(
          "jobId"
        )}`
      )
        .then((fetchEtdice) => {
          if (fetchEtdice.data?.detailed.length === 0) {
            return
          } 
          const etdiceData = fetchEtdice.data?.detailed?.map(
                (etdices: any, i: number) => {
              const acc = Object.keys(etdices)[0];
              return { x: etdices.time, y: etdices[acc] };
                }
              );
    
              const jobEtdice: ClientLineData[] = [
                {
                  clientId,
                  id:jobId,
                  color: colors.greenAccent[500],
                  data: etdiceData,
                },
              ];

          setEtdice(jobEtdice);
        })
        .catch((tcdiceReason) => {});    
      } finally {
      setIsLoading(false);
    }
  },[jobId]);

  const hasAccuracyData = accuracy?.length && accuracy[0].data?.length;
  const hasEpochData = epochs?.length && epochs[0].data?.length;

  
  return (
    <>
      
      <Box m="0 100px 10px 100px">
        <Header title="Results" subtitle="" />
      </Box>
      
      <Box m="0 100px 10px 100px" style={{ display: tcdice.length>0 ? 'grid':'none'}}>
        <Box
          sx={{
            gridColumn: "span 8",
            gridRow: "span 2",
            backgroundColor: colors.primary[400],
          }}
        >
          <Box
            mt="25px"
            p="10px 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                TC Dice
              </Typography>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart isDashboard={true} data={tcdice} />
          </Box>
        </Box>
      </Box>
      <Box m="0 100px 10px 100px" style={{ display: wtdice.length > 0 ? 'grid' : 'none' }}>
        <Box
          sx={{
            gridColumn: "span 8",
            gridRow: "span 2",
            backgroundColor: colors.primary[400],
          }}
        >
          <Box
            mt="25px"
            p="10px 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                WT Dice
              </Typography>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart isDashboard={true} data={wtdice} />
          </Box>
        </Box>
      </Box>
      <Box m="0 100px 0 100px" style={{ display: etdice.length > 0 ? 'grid' : 'none' }}>
        <Box
          sx={{
            gridColumn: "span 8",
            gridRow: "span 2",
            backgroundColor: colors.primary[400],
          }}
        >
          <Box
            mt="25px"
            p="10px 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                ET Dice
              </Typography>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart isDashboard={true} data={etdice} />
          </Box>
        </Box>
      </Box>
      <Box m="0 100px 0 100px">
        <Snackbar open={accuracyMessage.length > 0}>
          <Alert
            variant="filled"
            severity="error"
            onClose={() => {
              setAccuracyMessage("");
            }}
          >
            {" "}
            {`Retrieving Accuracy: ${accuracyMessage}`}
          </Alert>
        </Snackbar>
        <Snackbar open={epochsMessage.length > 0}>
          <Alert
            variant="filled"
            severity="error"
            onClose={() => {
              setEpochsMessage("");
            }}
          >
            {" "}
            {`Retrieving Epochs: ${epochsMessage}`}
          </Alert>
        </Snackbar>

        <Box
          sx={{
            gridColumn: "span 8",
            gridRow: "span 2",
            backgroundColor: colors.primary[400],
          }}
        >
          <Box
            mt="25px"
            p="10px 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                Accuracy
              </Typography>
            </Box>

          </Box>
          {!hasAccuracyData ?
            <Box height="250px" m="-20px 0 0 0">
              Loading accuracy
            </Box> :
            <Box height="250px" m="-20px 0 0 0">
              <LineChart isDashboard={true} data={accuracy} />
            </Box>}
        </Box>
      </Box>
      <Box m="0 100px 0 100px">
        <Box
          sx={{
            gridColumn: "span 8",
            gridRow: "span 2",
            backgroundColor: colors.primary[400],
          }}
        >
          <Box
            mt="25px"
            p="10px 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                Epoch
              </Typography>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart isDashboard={true} data={epochs} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ShowResults;
