import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Login from "./components/Login";
import useUser from "./hooks/UseUser";
import AllApps from "./scenes/allApps";
import AllJobs from "./scenes/allJobs";
import ClientServerStatus from "./scenes/ClientServerStatus";
import Dashboard from "./scenes/dashboard";
import FAQ from "./scenes/faq";
import Sidebar from "./scenes/global/Sidebar";
import Topbar from "./scenes/global/Topbar";
import Line from "./scenes/line";
import ShowResults from "./scenes/showResults";
import UploadApp from "./scenes/uploadApp";
import DownloadModel from "./scenes/downloadModel";
import LogoutApp from "./scenes/logout";
import { ColorModeContext, useMode } from "./theme";
import { BASE_PATH, PATHS } from "./utils/PathUtils";

function App() {
  const [theme, colorMode] = useMode();
  const [user, setUser] = useUser();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {!user ? (
          <div style={{ margin: "100px auto", display: "Block", width: 500 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="iFusion" subtitle="Log in" />
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Login onUserSet={setUser}/>
            </Box>
          </div>
        ) : (
          <div className="app">
            <Sidebar />
            <main className="content">
              <Topbar />
              <Routes>
                <Route path={BASE_PATH} element={<Dashboard />} />
                <Route path={PATHS.LOGOUT} element={<LogoutApp />} />
                <Route path={PATHS.HOME} element={<Dashboard />} />
                <Route path={PATHS.ALL_JOBS} element={<AllJobs />} />
                <Route path={PATHS.ALL_APPS} element={<AllApps />} />
                <Route
                  path={PATHS.CLIENT_SERVER_STATUS}
                  element={<ClientServerStatus />}
                />
                <Route path={PATHS.SHOW_RESULTS} element={<ShowResults />} />
                <Route path={PATHS.LINE} element={<Line />} />
                <Route path={PATHS.FAQ} element={<FAQ />} />
                <Route path={PATHS.DOWNLOAD_MODEL} element={<DownloadModel />} />
                <Route
                  path={PATHS.UPLOAD_APP}
                  element={<UploadApp />}
                />

                {/* <Route path={`${BASE_PATH}calendar`} element={<Calendar />} /> */}
                {/* <Route path={PATHS.GEOGRAPHY} element={<Geography />} /> */}
              </Routes>
            </main>
          </div>
        )}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
