import { Box, Button, TextField, useTheme } from "@mui/material";
import React, { useState } from "react";
import useUser, { UserData } from "../hooks/UseUser";
import { PATHS } from "../utils/PathUtils";

interface LoginProps {
  onUserSet: (userData: UserData) => void;
}

const Login = (props: LoginProps) => {
  const [user, setUser] = useUser();
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const theme = useTheme();
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      setIsAuthenticating(true);
      const email = data.get("email");
      if (!email) {
        return;
      }
      const userData: UserData = {
        sub: email as string,
        exp: Date.now() / 1000 + 30 * 24 * 3600,
      };


      fetch(`${PATHS.FED_ML}/api/v1/login`, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: data.get("email"),
          password: data.get("password"),
        }),
        method: "POST",
        
      })
        .then((value) => {
          setIsAuthenticating(true);
          window.localStorage.setItem("userData", JSON.stringify(userData));
          props.onUserSet(userData);
          console.log("Succeeded");
        })
        .catch((reason) => {
          setIsAuthenticating(false);
          console.log("failed:" + reason);
        });
    } finally {
      setIsAuthenticating(false);
    }
  };

 

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
      />

      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Sign In
      </Button>
    </Box>
  );
};

export default Login;
