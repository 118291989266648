import CancelIcon from "@mui/icons-material/Cancel";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Alert,
  Box,
  Button,
  IconButton,
  Snackbar,
  useTheme,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { PATHS } from "../../utils/PathUtils";

interface Job {
  id: number;
  "JOB ID": string;
  NAME: string;
  "RUN DURATION": string;
  STATUS: string;
  "SUBMIT TIME": string;
  CLIENT: string;
}

const AllJobs = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [statusMessage, setStatusMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [jobs, setJobs] = useState<Job[]>([]);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    setIsLoading(true);
    try {
      axios(`${PATHS.FED_ML}/fedml/list/jobs`)
        .then((result) => {
          let rjobs = result.data.reverse();
          rjobs = rjobs.map((x: Job, index:number) => {
            return {
              ...x,
              "SUBMIT TIME": new Date(x["SUBMIT TIME"]).toLocaleString(),
              id:index
            };
          });

          setJobs(rjobs);
        })
        .catch((reason) => {
          setError(reason);
          console.error("Failed to get jobs", reason);
        });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const abortJob = async (jobId: string) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${PATHS.FED_ML}/fedml/task/abort-job/${jobId}`,
    };
    const response = await axios.request(config);
    setStatusMessage(response.data.response);
  };

  const deleteJob = async (jobId: string) => {
    console.log("Job ID", jobId);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${PATHS.FED_ML}/fedml/task/delete-job/${jobId}`,
    };
    const response = await axios.request(config);
    setStatusMessage(response.data.details.message);
  };

  const redirectToResult = (jobId: string, clientId: string) => {
    console.log("Job ID", jobId);
    console.log("Client ID", clientId);
    window.location.href = `/showResults?jobId=${jobId}&clientId=${clientId}`;
  };
  const columns = [
    { field: "JOB ID", headerName: "Job ID", flex: 1 },
    { field: "NAME", headerName: "Name", flex: 0.5 },
    { field: "CLIENT", headerName: "Client", flex: 0.5 },
    { field: "RUN DURATION", headerName: "Run Duration", flex: 0.5 },
    { field: "STATUS", headerName: "Status", flex: 1 },
    { field: "SUBMIT TIME", headerName: "Submit Time", flex: 1 },
    {
      field: "Show Result",
      headerName: "",
      flex: 1,
      renderCell: (params: { row: Job }) => {
        return (
          <Link
            style={{color: colors.blueAccent[200]}}
            to={`${PATHS.SHOW_RESULTS}?jobId=${params.row["JOB ID"]}&clientId=${params.row["CLIENT"]}`}          >
            Show Result
          </Link>
        );
      },
    },
    {
      field: "ABORT",
      headerName: "Abort",
      flex: 0.5,
      renderCell: (params: { row: Job }) => {
        return (
          <IconButton
            color="warning"
            onClick={() => abortJob(params.row["JOB ID"])}
          >
            <CancelIcon />
          </IconButton>
        );
      },
    },
    {
      field: "DELETE",
      headerName: "Delete",
      flex: 0.5,
      renderCell: (params: { row: Job }) => {
        return (
          <IconButton
            color="error"
            onClick={() => deleteJob(params.row["JOB ID"])}
          >
            <DeleteForeverIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <Box m="0 100px 0 100px">
      <Header title="All Jobs" subtitle="" />
      <Snackbar open={statusMessage.length > 0} message="">
        <Alert
          variant="filled"
          severity="error"
          onClose={() => {
            setStatusMessage("");
          }}
        >
          {" "}
          {statusMessage}
        </Alert>
      </Snackbar>
      <Box
        m="0 0 50px 0"
        height="73vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <>
          {error && (
            <div>
              <h1>Could not retrieve data</h1>
            </div>
          )}

          {!isLoading && !error && (
            <DataGrid
              rows={jobs}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
            />
          )}
        </>
      </Box>
    </Box>
  );
};

export default AllJobs;
