import React, {useState, useEffect} from 'react'
import { Box, IconButton, Alert, Snackbar } from "@mui/material"
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { DataGrid, gridColumnsTotalWidthSelector, GridToolbar } from "@mui/x-data-grid"
import { tokens } from "../../theme"
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import axios from "axios"
import LinearProgress from '@mui/material/LinearProgress';
import { PATHS } from '../../utils/PathUtils';

const ClientServerStatus = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [clientStatus, setClientStatus] = useState([])
    const [serverStatus, setServerStatus] = useState([])
    const [serverEngineStatus, setServerEngineStatus] = useState('LOADING ...')
    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')


    

    const getClientStatus = async () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${PATHS.FED_ML}/fedml/client/status`,
            headers: {}
        }
        const clientData = await axios.request(config)
        clientData.data.client_statuses.shift()
     

        const clientDataWithId = clientData.data.client_statuses
            .map((item: any, index: number)=>{
                interface temporaryObject {
                    id: number,
                    CLIENT: string,
                    APP_NAME: string,
                    JOB_ID: string,
                    STATUS: string
                }
                const tempObject: temporaryObject = {
                    id: index,
                    CLIENT: item[0],
                    APP_NAME: item[1],
                    JOB_ID: item[2],
                    STATUS: item[3]
                }
                return tempObject
            })
        setClientStatus(clientDataWithId)
    }
    const getServerStatus = async () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${PATHS.FED_ML}/fedml/server/status`,
            headers: {}
        }
        const serverdata = await axios.request(config)
        setServerEngineStatus(serverdata.data.server_engine_status)
        serverdata.data.status_table.shift()
        const serverDataWithId = serverdata.data.status_table
            .map((item: any, index: number)=>{
                interface temporaryObject {
                    id: number,
                    CLIENT: string,
                    TOKEN: string,
                    'LAST CONNECT TIME': string
                }
                const tempObject: temporaryObject = {
                    id: index,
                    CLIENT: item[0],
                    TOKEN: item[1],
                    'LAST CONNECT TIME': item[2]
                }
                return tempObject
            })
        console.log('serverDataWithId', serverDataWithId)
        setServerStatus(serverDataWithId)
    }

    useEffect(() => {
        getClientStatus()
        getServerStatus()
    }, [])

  
    const restartClient = async() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${PATHS.FED_ML}/fedml/client/restart`,
            headers: {}
        }
        const restartMessage =  await axios.request(config)
        setAlertMessage(restartMessage.data.message)
    }
    const restartServer = async() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${PATHS.FED_ML}/fedml/server/restart`,
            headers: {}
        }
        const restartMessage =  await axios.request(config)
        setAlertMessage(restartMessage.data.message)
    }
    const clientColumnConfiguration = [
        { field: "CLIENT", headerName: "Client", flex: 1 },
        { field: "APP_NAME", headerName: "App Name", flex: 1 },
        { field: "JOB_ID", headerName: "Job Id", flex: 1 },
        { field: "STATUS", headerName: "Status", flex: 1 },
        { field: "restart", headerName: "Restart Client", hide: true,
            renderCell: () => {
                return (
                    <IconButton onClick={
                        async() => {
                            await restartClient()
                            setShowAlert(true)
                        }
                    }>
                        <RestartAltIcon />
                    </IconButton>
                    
                )
            }
    }
    ];

    const serverColumnConfiguration = [
        { field: "CLIENT", headerName: "Client", flex: 1 },
        { field: "TOKEN", headerName: "Token", flex: 1 },
        { field: "LAST CONNECT TIME", headerName: "Last Connect Time", flex: 1 },
        // {
        //     field: "restart", headerName: "", hide: true,
        //     renderCell: () => {
        //         return (
        //             <IconButton onClick={
        //                 async () => {
        //                     await restartServer()
        //                     setShowAlert(true)
        //                 }
        //             }>
        //                 <RestartAltIcon />
        //             </IconButton>

        //         )
        //     }
        // }
    ]

    const styleForDataGrid = {
        "& .MuiDataGrid-root": {
            border: "none",
        },
        "& .MuiDataGrid-cell": {
            borderBottom: "none",
        },
        "& .name-column--cell": {
            color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
        },
    }

    return (
        
        <Box m="0 100px 0 100px">
            
            <Header
                title="Client Status"
                subtitle=""
            />
            <Box
                m="0 0 50px 0"
                height="33vh"
                sx={styleForDataGrid}
            >
                
                <Snackbar
                    open={showAlert}
                    message="Note archived"
                >
                    < Alert variant="filled" severity="error" 
                    onClose={() => { 
                        setShowAlert(false)
                        setAlertMessage('')
                    }}
                    > {alertMessage}</Alert>
                </Snackbar>
                <DataGrid
                    loading={clientStatus.length === 0}
                    slots={{
                        loadingOverlay: LinearProgress,
                    }}
                    rows={clientStatus}
                    columns={clientColumnConfiguration}
                    components={{ Toolbar: GridToolbar }}
                />
            </Box>
            

            
            <Box m="40px 0 0 0"> 
            <Header
                title="Server Status"
                subtitle={`Server Engine Status ${serverEngineStatus.toUpperCase()}`}
            />
            <Box
                m="0 0 0 0"
                height="33vh"
                sx={styleForDataGrid}
            >
                <DataGrid
                    loading={serverStatus.length === 0}
                    slots={{
                        loadingOverlay: LinearProgress,
                    }}
                    rows={serverStatus}
                    columns={serverColumnConfiguration}
                    components={{ Toolbar: GridToolbar }}
                />
            </Box>
            </Box>
            
        </Box>
    );
};

export default ClientServerStatus