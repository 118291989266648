import React, {useState} from "react"
import { Box, Button, IconButton, Alert, Snackbar } from "@mui/material"
import { tokens } from "../../theme"
import Header from "../../components/Header"
import { useTheme } from "@mui/material"
import axios from "axios"
import { useMutation } from 'react-query'

const LogoutApp = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    window.localStorage.removeItem("userData");
    window.location.replace('/');
    return (
        <Box m="0 100px 0 100px">
            <Header
                title="Logged out"
                subtitle=""
            />
        </Box>
    );
};

export default LogoutApp